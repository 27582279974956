<template>
  <div class="city-container">
    <div class="flex city" v-show="!showCityEdit && !infinityEdit">
      <div>{{ label }}</div>
      <span
        >{{ city ? city : "Город не указан" }}
        <button type="button" @click="showCityEdit = true">
          <i class="fal fa-pen"></i></button
      ></span>
    </div>
    <div
      class="flex edit-city align-item-center"
      v-show="showCityEdit || infinityEdit"
    >
      <custom-select-2
        v-model="cityId"
        :label="label"
        name="city_id"
        :settings="cityAjax"
      />
      <button
        type="button"
        class="close-city-btn"
        v-if="!infinityEdit"
        @click="showCityEdit = false"
      >
        <img src="@/assets/images/icons/close.svg" alt="Значок отмены" />
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import useAjaxSelect from "@/mixins/useAjaxSelect";
import CustomSelect2 from "./CustomSelect2.vue";
export default {
  components: { CustomSelect2 },
  name: "city-input",
  props: {
    city: {
      type: String,
    },
    label: {
      type: String,
      default: "Город",
    },
    infinityEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const showCityEdit = ref(false);
    const cityId = ref(null);
    const { cityAjax } = useAjaxSelect();
    watch(cityId, () => {
      context.emit("changeCity", cityId.value);
    });

    return {
      showCityEdit,
      cityId,
      cityAjax,
    };
  },
};
</script>

<style scoped lang="scss">
.city-container {
  padding: 10px 0;
}
.city {
  padding-left: 10px;
  height: 62px;
  align-items: center;

  div {
    display: inline-block;
    font-size: 15px;
    flex: 0 0 25%;
    font-weight: 400;
    padding-bottom: 4px;
  }
  button:hover i {
    color: var(--main-color);
  }
  button {
    margin-left: 10px;
  }
}

.edit-city {
  .form-group {
    flex: 1 0 auto;
  }

  button {
    height: 20px;
    width: 20px;
    margin-left: 10px;
    img {
      max-width: 100%;
    }
  }
}
</style>