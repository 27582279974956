
export default function () {
    const setForm = (source, form) => {
        form.first_name = source.first_name ? source.first_name : null;
        form.last_name = source.last_name ? source.last_name : null;
        form.patronymic = source.patronymic ? source.patronymic : null;
        form.age = source.age ? source.age : null;
        form.sex = source.sex ? source.sex : null;
        form.profession_id = source.profession ? String(source.profession.id) : null;
        form.position_id = source.position ? String(source.position.id) : null;
        form.industry_id = source.industry_id ? String(source.industry_id.id) : null;
        form.city_id = source.city ? String(source.city.id) : null;
        form.city = source.city ? source.city.name : null;
        form.currentImage = source.image ? source.image : null;
        form.email = source.email ? source.email : null;
        form.company = source.company ? source.company : null;
        form.image = null;
        form.role = source.role ? source.role : null;
        form.company_id = source.company ? String(source.company.id) : null
    }
    return {setForm}
}